import { Tenant } from '@whitelabel/Entities/Tenants.entity';

export const generateSnowplowAppId = ({ tenant }: { tenant?: Tenant }) => {
  const suffix: { [key: string]: string } = {
    staging: '_stg',
    development: '_dev',
    production: '',
  };

  return `${tenant ?? 'i15'}${
    suffix?.[process.env.NEXT_PUBLIC_APP_ENV || 'development'] ?? suffix.development
  }`;
};
